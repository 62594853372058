'use client'

import { useEffect } from 'react'

import Analytics from '../../../../module/analytics'

function getUtmData() {
  const getCookies = (name: string) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))

    return match ? match[2] : null
  }

  const cookieValue = getCookies('utmdt')
  if (!cookieValue) {
    const analyticsData = Analytics()
    const utmCookies = {
      source: analyticsData.source,
      medium: analyticsData.medium,
      utmCampaign: analyticsData?.campaign,
      utmContent: analyticsData?.content,
      utmMedium: analyticsData?.medium,
      utmSource: analyticsData?.source,
      utmTerms: analyticsData?.terms
    }

    document.cookie = `utmdt=${JSON.stringify(utmCookies)};max-age=300;domain=${window.location.hostname}`
  }
}

export const UTMClient = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      getUtmData()
    }
  }, [])

  return <></>
}
